import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GetTrackingHistory,
  GetWayBillsByOrderNo,
} from "../../api/AxiosInterceptors";
import TrackingModal from "../../components/modals/trackingModals/trackingModal";
import UtilityClass from "../../utilities/UtilityClass";
import { EnumReturnStatusLookup } from "../../utilities/enum";
import {
  ActionButtonCustom,
  CicrlesLoading,
  GridContainer,
  GridItem,
  TimeLineComponent,
  TimeLineItemComponent,
  fetchMethod,
  handleDownloadPdf,
  useGetLocationOrPathOrSearchParams,
} from "../../utilities/helpers/Helpers";

export default function TrackingHistory() {
  const { path } = useGetLocationOrPathOrSearchParams();
  const replacedPath = path.replace("/", "");
  const [clientId, orderId] = replacedPath.split("_");
  const [loading, setLoading] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState({
    open: false,
    loading: {},
  });
  const [trackingData, setTrackingData] = useState({ loading: true, data: {} });

  const getWayBillsByOrderNo = async (data) => {
    setLoading(true);
    const body = {
      orderNos: data?.ReturnOrderNo,
      ClientId: clientId,
    };
    await GetWayBillsByOrderNo(body)
      .then((res) => handleDownloadPdf(res, "AWB"))
      .catch((e) => {
        console.log("e", e);
      })
      .finally((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const fetchTrackingData = async () => {
      const { response } = await fetchMethod(
        () => GetTrackingHistory(path.replace("/", "")),
        setTrackingData
      );
      if (response && response.result) {
        setTrackingData((prev) => ({ ...prev, data: response.result }));
      }
    };
    fetchTrackingData();
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchTrackingData();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [path]);

  return (
    <>
      {trackingData.loading ? (
        <CicrlesLoading />
      ) : (
        <>
          <Box
            sx={{
              marginTop: 0,
              backgroundColor: "rgb(86, 58, 213)",
              minHeight: "100vh",
              p: 2,
              position: "relative",
            }}
          >
            <Grid container spacing={2} justifyContent="center" mb={1}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Avatar
                        sx={{
                          bgcolor: "#bbdefb",
                          width: 40,
                          height: 40,
                          p: 0.5,
                        }}
                        src={
                          trackingData?.data?.orderAddress?.store?.StoreImage
                        }
                      >
                        {trackingData?.data?.orderAddress?.store?.StoreName?.slice(
                          0,
                          2
                        ).toUpperCase() || ""}
                      </Avatar>
                    </Box>
                    <Box sx={{ ml: 0.5, alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        color="#fff"
                        sx={{
                          fontWeight: "bold",
                          fontSize: 12,
                          backgroundColor: "#2c237a",
                          p: 0.5,
                          borderRadius: "4px",
                        }}
                      >
                        {UtilityClass.convertUtcToLocal(
                          trackingData?.data?.orderAddress?.customer?.CreatedOn
                        )}
                      </Typography>
                      <Typography
                        color="#fff"
                        variant="h5"
                        sx={{ fontWeight: "bold", mt: 0.5, ml: 1 }}
                      >
                        {trackingData?.data?.orderAddress?.store?.StoreName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        md: "row",
                        sm: "row",
                        xs: "column-reverse",
                      },
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Chip
                      label={`Status:${trackingData?.data?.CarrierTrackingStatus}`}
                      sx={{
                        backgroundColor: "#f1b717",
                        color: "#fff",
                        fontWeight: "bold",
                        borderRadius: 2,
                      }}
                    />
                    {trackingData?.data?.returnOrder?.isAllowReturn &&
                      !trackingData?.data?.returnOrder?.isReturnExist && (
                        <Button
                          variant="contained"
                          onClick={() =>
                            setOpenTrackingModal((prev) => ({
                              ...prev,
                              open: true,
                            }))
                          }
                          sx={{
                            background: "#fff",
                            color: "black",
                            textTransform: "none",
                            borderRadius: "20px",
                            padding: "6px 10px",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "14px",
                            gap: "10px",
                            border: "1px solid #6f2dc4",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                            transition: "all 0.3s ease",
                            "&:hover": {
                              background:
                                "linear-gradient(90deg, #835EF8 0%, #563AD5 100%)",
                              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
                              color: "#fff",
                              "& .MuiSvgIcon-root": {
                                color: "#fff", // Icon color on hover
                              },
                            },
                          }}
                        >
                          Order Help
                          <HelpOutlineOutlinedIcon
                            sx={{
                              color: "#6f2dc4",
                              fontSize: "20px",
                            }}
                          />
                        </Button>
                      )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Card sx={{ backgroundColor: "#f7f7ff", borderRadius: 2 }}>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <Avatar
                          sx={{
                            bgcolor: "#bbdefb",
                            width: 40,
                            height: 40,
                            p: 0.5,
                          }}
                          src={
                            trackingData?.data?.orderAddress?.store?.StoreImage
                          }
                        >
                          {trackingData?.data?.orderAddress?.customer?.CustomerName?.slice(
                            0,
                            2
                          ).toUpperCase() || ""}
                        </Avatar>
                      </Box>
                      <Box sx={{ ml: 1.5, alignItems: "center" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          {
                            trackingData?.data?.orderAddress?.customer
                              ?.CustomerName
                          }
                          , {trackingData?.data?.orderAddress?.customer?.Mobile}
                        </Typography>
                        <Typography variant="caption" color="gray">
                          {
                            trackingData?.data?.orderAddress?.customer
                              ?.CustomerFullAddress
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* Items List */}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Card sx={{ mt: 1, backgroundColor: "#fff", borderRadius: 2 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: "bold", color: "#333" }}
                      >
                        Items
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ color: "#333", fontSize: "14px" }}
                      >
                        OrderNo:{trackingData?.data?.paymentDetail?.orderNo}
                      </Typography>
                    </Box>
                    {trackingData?.data?.orderItems?.map((item, index) => (
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        sx={{ mb: 2 }}
                        key={item?.OrderItemId}
                      >
                        <Grid item>
                          <Avatar
                            variant="square"
                            src="https://via.placeholder.com/40"
                            alt={item?.ProductName || "Item"}
                            sx={{ width: 55, height: 55 }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography>
                            {item?.OrderItemDescription || "N/A"}
                          </Typography>
                          <Typography color="textSecondary">
                            AED {item?.Price.toFixed(2)} x{" "}
                            {item?.OrderItemQuantity}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {item.Price > 0
                              ? `AED${(item?.Price).toFixed(2)}`
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}

                    <Divider sx={{ mt: 2 }} />
                    <Box sx={{ mt: 3 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        <Typography>Subtotal</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          AED {trackingData?.data?.taxInfo?.Amount.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        <Typography>Shipping</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          AED{" "}
                          {trackingData?.data?.taxInfo?.CShippingCharges.toFixed(
                            2
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        <Typography>Tax</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          AED {trackingData?.data?.taxInfo?.TotalTax.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Divider sx={{ mb: 2 }} />
                      <Grid container justifyContent="space-between">
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Order total
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          AED {trackingData?.data?.taxInfo?.Amount.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Box>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={{ mt: 1 }}
              >
                <Grid item xs={12} md={6} pt={"0 !important"}>
                  <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
                    <CardContent>
                      <TimeLineComponent>
                        {trackingData.data?.history?.map(
                          (value, index, arr) => (
                            <TimeLineItemComponent
                              key={index}
                              index={index}
                              data={trackingData.data}
                              TimelineOppositeContentData={
                                <Typography variant="h5" fontWeight={400}>
                                  {UtilityClass.convertUtcToLocal(
                                    value.CreatedOn
                                  )}
                                </Typography>
                              }
                              TimelineContentData={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography variant="h5">
                                    <span style={{ fontWeight: 400 }}>
                                      Status changed to{" "}
                                    </span>
                                    {value.TrackingStatus}
                                  </Typography>
                                  {trackingData?.data?.returnOrder
                                    ?.isReturnExist &&
                                    value?.TrackingStatus ===
                                      EnumReturnStatusLookup.Approved &&
                                    value.ReturnOrderNo !== "" && (
                                      <Box ml={1}>
                                        <ActionButtonCustom
                                          label={"Download Awb"}
                                          loading={loading}
                                          startIcon={
                                            <FileDownloadOutlinedIcon />
                                          }
                                          onClick={() =>
                                            getWayBillsByOrderNo(value)
                                          }
                                        />
                                      </Box>
                                    )}
                                </Box>
                              }
                            />
                          )
                        )}
                      </TimeLineComponent>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        marginBottom={"6px !important"}
                      >
                        {trackingData?.data?.returnOrder?.isReturnExist &&
                          trackingData?.data?.returnOrder?.isAllowReturn && (
                            <Typography variant="h4">
                              Return Request Submitted
                            </Typography>
                          )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <Grid item xs={12} md={6} pt={"0 !important"}>
                <Card sx={{ borderRadius: 4, padding: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                      Payment Details
                    </Typography>
                    <Chip
                      label={trackingData?.data?.paymentDetail?.paymentStatus}
                      color={
                        trackingData?.data?.paymentDetail?.paymentStatus ===
                        "PAID"
                          ? "success"
                          : "warning"
                      }
                      size="small"
                    />
                  </Box>
                  <CardContent
                    sx={{
                      display: "flex",
                      padding: "4px 16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <GridContainer spacing={2}>
                      <GridItem md={6} sm={6} xs={12}>
                        <Typography variant="body2">
                          <strong>Order No: </strong>{" "}
                          {trackingData?.data?.paymentDetail?.orderNo}
                        </Typography>
                      </GridItem>
                      <GridItem md={6} sm={6} xs={12}>
                        <Typography variant="body2">
                          <strong>Amount: </strong>{" "}
                          {trackingData?.data?.paymentDetail?.currency}{" "}
                          {trackingData?.data?.paymentDetail?.amount.toFixed(2)}
                        </Typography>
                      </GridItem>
                      <GridItem md={6} sm={6} xs={12}>
                        <Typography variant="body2">
                          <strong>Payment Via: </strong>{" "}
                          {trackingData?.data?.paymentDetail?.paymentVia}
                        </Typography>
                      </GridItem>
                      <GridItem md={6} sm={6} xs={12}>
                        {trackingData?.data?.paymentDetail?.paidOn && (
                          <Typography variant="body2">
                            <strong>Paid On: </strong>{" "}
                            {UtilityClass.convertUtcToLocal(
                              trackingData?.data?.paymentDetail?.paidOn
                            )}
                          </Typography>
                        )}
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ position: "fixed", bottom: 0, right: 1 }}
            >
              <Grid item xs={12} md={6} pt={"0 !important"}>
                {trackingData?.data?.paymentLink && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      window.open(trackingData?.data?.paymentLink, "_blank")
                    }
                    sx={{
                      backgroundColor: "green",
                      color: "white",
                      textTransform: "none",
                      borderRadius: "10px",
                      display: "flex",
                      width: "98%",
                      margin: "auto",
                      alignItems: "center",
                      fontWeight: 600,
                      gap: "8px",
                      border: "1px solid green",
                      "&:hover": {
                        backgroundColor: "#2e7d32",
                      },
                    }}
                  >
                    <PaymentIcon sx={{ color: "white" }} fontSize="small" />
                    Pay Online
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {openTrackingModal.open && (
        <TrackingModal
          open={openTrackingModal.open}
          onClose={() =>
            setOpenTrackingModal((prev) => ({ ...prev, open: false }))
          }
          trackingData={trackingData}
        />
      )}
    </>
  );
}
